import {
    validateBoundingBox,
    bool as validateBool, string as validateString, trackingId as validateTrackingId,
    oneOfValue as validateOneOfValue, naturalInteger as validateNaturalInteger,
    languageCode as validateLanguageCode, point as validatePoint, countrySet as validateCountrySet,
    number as validateNumber, mapcodes as validateMapcodes, limit as validateLimit,
    connectorSet as validateConnectorSet, fuelSet as validateFuelSet, offset as validateOffset
} from '../../services/validators';
import {
    convertBoundingBox, searchLanguage as convertLanguage, point as convertPoint
} from '../../services/converters';

import { SEARCH } from '../../common/searchTypes';
import parameterApplications from '../../common/parameterApplications';
import {notRequiredWithCategoryNorBrandSet} from '../postValidators';
import { v4 as uuid } from 'uuid';

export default {
    create: () => {
        return {
            __all: {
                validators: [notRequiredWithCategoryNorBrandSet]
            },
            key: {
                validators: [validateString]
            },
            trackingId: {
                validators: [validateTrackingId],
                application: parameterApplications.HEADER,
                defaultValue: uuid
            },
            query: {
                validators: [
                    validateString
                ],
                application: parameterApplications.PATH,
                defaultValue: ''
            },
            typeahead: {
                validators: [validateBool]
            },
            limit: {
                validators: [validateLimit]
            },
            offset: {
                validators: [validateOffset],
                name: 'ofs'
            },
            language: {
                validators: [validateLanguageCode],
                converters: [convertLanguage]
            },
            countrySet: {
                validators: [validateCountrySet]
            },
            radius: {
                validators: [validateNaturalInteger]
            },
            center: {
                converters: [convertPoint], validators: [validatePoint],
                cast: (center, requestOptions) => {
                    const coordinates = center.split(',');
                    requestOptions.lat = coordinates[0];
                    requestOptions.lon = coordinates[1];
                }
            },
            type: {
                validators: [validateString],
                defaultValue: SEARCH,
                application: parameterApplications.PATH
            },
            bestResult: {
                validators: [validateBool],
                cast: (bestResults, requestOptions) => {
                    if (!bestResults) {
                        return;
                    }
                    requestOptions.limit = 1;
                    requestOptions.ofs = 0;
                }
            },
            protocol: {
                validators: [validateOneOfValue(['http', 'https'], 'protocol')]
            },
            extendedPostalCodesFor: {
                validators: [validateString]
            },
            view: {
                validators: [validateOneOfValue(['IL', 'MA', 'IN', 'PK', 'Unified', 'RU', 'TR', 'AR', 'CN'], 'view')]
            },
            brandSet: {
                validators: [validateString]
            },
            categorySet: {
                validators: [validateString]
            },
            connectorSet: {
                validators: [validateConnectorSet]
            },
            minPowerKW: {
                validators: [validateNumber]
            },
            maxPowerKW: {
                validators: [validateNumber]
            },
            openingHours: {
                validators: [validateOneOfValue(['nextSevenDays'], 'openingHours parameter')]
            },
            timeZone: {
                validators: [validateOneOfValue(['iana'], 'timeZone parameter')]
            },
            mapcodes: {
                validators: [validateMapcodes]
            },
            fuelSet: {
                validators: [validateFuelSet]
            },
            relatedPois: {
                validators: [validateOneOfValue(['off', 'child', 'parent', 'all'], 'relatedPois')]
            }
        };
    }
};

export const boundingBox = () => ({
    validators: [validateBoundingBox],
    converters: [convertBoundingBox],
    cast: (boundingBox, requestOptions) => {
        requestOptions.topLeft = `${boundingBox.maxLat},${boundingBox.minLon}`;
        requestOptions.btmRight = `${boundingBox.minLat},${boundingBox.maxLon}`;
    }
});

