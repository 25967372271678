import {SERVICE_TYPES} from 'Core/serviceTypes';
import parameterApplications from '../../common/parameterApplications';
import { v4 as uuid } from 'uuid';
import {
    string as validateString, trackingId as validateTrackingId, languageCode as validateLanguageCode
} from '../validators';
import { Endpoints } from '../../endpoints/endpointsManager';

import { modelResponse } from '../../model/modelResponse';
import {
    singleRequestServiceFactory,
    serviceFactory
} from '../../core';

/**
 * The POI Categories service provides a full list of POI (Points of Interest) categories and subcategories together
 * with their translations and synonyms.
 * [Search API - POI Categories](POI_CATEGORIES_URL).
 *
 * Parameters need to be be passed to the constructor.
 *
 * ### Response
 * The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * function callbackFn(result) {
 *   console.log(result);
 * };
 *
 * tt.services.poiCategories({
 *   key: <Your API key>,
 *   language: 'pl-PL'
 * }).then(callbackFn);
 * ```
 *
 * @class poiCategories
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses LanguageMixin
 * @uses TrackingIdMixin
 * @uses AbortSignalMixin
 * @constructor
 *
 * @param {Object} [options] Options to be passed to the call.
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 */

const fields = {
    key: {
        validators: [validateString]
    },
    trackingId: {
        validators: [validateTrackingId],
        application: parameterApplications.HEADER,
        defaultValue: uuid
    },
    language: {
        validators: [validateLanguageCode]
    }
};

export function poiCategories(options, additionalOptions) {
    const handleServiceCall = (data, abortSignal) => {
        const endpoints = new Endpoints(additionalOptions);
        const singleRequest = singleRequestServiceFactory(endpoints.resolve('poiCategoriesEndpoint'));
        return singleRequest(fields, data, abortSignal).then(modelResponse);
    };
    return serviceFactory(
        fields,
        SERVICE_TYPES.SEARCH,
        'poiCategories',
        handleServiceCall
    )(options, additionalOptions);
}
