/**
 * @deprecated
 *
 * The matrixRouting service has been deprecated.
 * <ul>
 * <li>This service will be withdrawn following a 12 months deprecation period.</li>
 * <li>The planned withdrawal period is October 14, 2023.</li>
 * <li>Following withdrawal, requests to this service may receive an HTTP 404 error in response.</li>
 * </ul>
 *
 * **Instead you can use [Matrix Routing API V2](MATRIX_ROUTING2_URL).
 * Note that Matrix Routing API V2 is not supported in Web SDK.**
 *
 * @description
 *
 * Matrix Routing service implementation using using the TomTom
 * [Routing API - Matrix Routing](MATRIX_ROUTING_URL).
 *
 * The Routing API Matrix Routing service allows the calculation of a matrix of route summaries for a set of routes
 * which are defined with origin and destination locations.
 *
 * For every given origin this service calculates
 * the cost of routing from that origin to every given destination. The set of origins and the set of destinations
 * can be thought of as the column and row headers of a table, and each cell in the table contains the costs of routing
 * from the origin to the destination for that cell.
 *
 * The following costs are computed for each route:
 * - Travel times
 * - Distances
 *
 * Parameters need to be passed to the constructor.
 *
 * If you want to explicitly decide which batch mode you want to use, add a property 'batchMode' with its value
 * set to one of the properties: sync, async, or redirect.
 *
 * The response is also extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * For more information, please refer to the Matrix Routing documentation
 * [here](MATRIX_ROUTING_URL).
 *
 *
 * @class matrixRouting
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses TrackingIdMixin
 * @uses CommonRoutingParameters
 * @uses AbortSignalMixin
 *
 * @constructor
 * @param {Object|Array} [options] Options to be passed to the routing call,
 *     or an array of such options objects to perform a batch request.
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 *
 * @example
 * ```js
 * var origins = [
 *   { point: { latitude: 51.63685, longitude: 19.4171 } },
 *   { point: { latitude: 51.63685, longitude: 19 } }
 * ];
 * var destinations = [
 *   { point: { latitude: 52.23498, longitude: 21.00849 } },
 *   { point: { latitude: 52.63685, longitude: 18 } }
 * ];
 * function callbackFn(routeGeoJson) {
 *   console.log(routeGeoJson);
 * }
 * tt.services.matrixRouting({
 *   key: <Your API key>,
 *   origins: origins,
 *   destinations: destinations,
 *   traffic: true
 * }).then(callbackFn);
 * ```
 */
/* jshint nomen:false */
import {SERVICE_TYPES} from 'Core/serviceTypes';
import {matrixRoutingModel} from 'model/routing/matrixRouting';
import {matrixParameters} from './matrixParameters';
import {modelResponse} from '../../model/modelResponse';
import { Endpoints } from '../../endpoints/endpointsManager';
import {
    matrixFactory,
    serviceFactory
} from '../../core';

export function matrixRouting(options, additionalOptions) {
    const fields = matrixParameters();
    const maxNoOfElementsInBatchRequest = 100;
    const endpoints = new Endpoints(additionalOptions);
    const matrixRequest = matrixFactory(maxNoOfElementsInBatchRequest, {
        sync: endpoints.resolve('matrixSyncRoutingEndpoint'),
        async: endpoints.resolve('matrixRoutingEndpoint')
    });
    const handleServiceCall = (data, abortSignal) => {
        // eslint-disable-next-line max-len
        console.error('The Matrix Routing v1 will be discontinued since October 14th, 2023 and replaced with Matrix Routing v2.\n\nWeb SDK does not support Matrix Routing v2.\nPlease use the Routing API instead. \n For more information, see https://developer.tomtom.com/routing-api/documentation/matrix-routing-v2/matrix-routing-v2-service');
        return matrixRequest(fields, data, abortSignal).then(response => modelResponse(response, matrixRoutingModel));
    };

    return serviceFactory(
        fields,
        SERVICE_TYPES.ROUTING,
        'matrixRouting',
        handleServiceCall
    )(options, additionalOptions);
}
