/* eslint-disable object-curly-spacing */
/* eslint-disable no-undef */
/**
 * Retrieve polygons (in GeoJSON format) representing administrative borders using IDs related to
 * search results entities.
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * tt.services.fuzzySearch({
 *      key: <Your API key>,
 *      query: "Amsterdam"
 * }).then(function(response) {
 *      var geometryId = response.results[0].dataSources.geometry.id;
 *      tt.services.additionalData({
 *          key: <Your API key>,
 *          geometries: [geometryId]
 *      }).then(function(adpResponse) {
 *          console.log(adpResponse);
 *      })
 *   });
 * ```
 *
 * @class additionalData
 * @module Services
 * @namespace Services.services
 * @uses KeyMixin
 * @uses BatchMixin
 * @uses GeometriesMixin
 * @uses GeometriesZoomMixin
 * @uses TrackingIdMixin
 * @uses AbortSignalMixin
 * @constructor
 *
 * @param {Object} [options]
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 */

import {SERVICE_TYPES} from 'Core/serviceTypes';
import {
    string as validateString, trackingId as validateTrackingId, arrayType as validateArrayType,
    geometriesZoom as validateGeometriesZoom
} from '../validators';
import { batchModel } from '../../model/search/batchModel';
import parameterApplications from '../../common/parameterApplications';
import {modelResponse} from '../../model/modelResponse';
import { v4 as uuid } from 'uuid';
import { Endpoints } from '../../endpoints/endpointsManager';
import {
    serviceFactory,
    singleRequestServiceFactory,
    batchRequestServiceFactory
} from '../../core';

const fields = {
    key: {
        validators: [validateString]
    },
    trackingId: {
        validators: [validateTrackingId],
        application: parameterApplications.HEADER,
        defaultValue: uuid
    },
    geometries: {
        validators: [validateArrayType],
        required: true
    },
    geometriesZoom: {
        validators: [validateGeometriesZoom]
    }
};

export function additionalData(options, additionalOptions) {
    const endpoints = new Endpoints(additionalOptions);
    const singleAdpRequest = singleRequestServiceFactory(endpoints.resolve('adpEndpoint'));
    const batchAdpRequest = batchRequestServiceFactory(100, {
        single: endpoints.resolve('batchAdpQueryEndpoint'),
        batchSync: endpoints.resolve('batchSyncSearchEndpoint'),
        batch: endpoints.resolve('batchSearchEndpoint')
    });

    function handleServiceCall(data, abortSignal) {
        return singleAdpRequest(fields, data, abortSignal).then(modelResponse);
    }
    function handleBatchServiceCall(data, abortSignal) {
        return batchAdpRequest(fields, data, abortSignal).then(response => modelResponse(response, batchModel));
    }

    return serviceFactory(
        fields,
        SERVICE_TYPES.SEARCH,
        'additionalData',
        handleServiceCall,
        handleBatchServiceCall
    )(options, additionalOptions);
}
