import {
    bool as validateBool, string as validateString, trackingId as validateTrackingId,
    validateBoundingBox, oneOfValue as validateOneOfValue,
    positiveInteger as validatePositiveInteger, naturalInteger as validateNaturalInteger,
    languageCode as validateLanguageCode, point as validatePoint, countrySet as validateCountrySet,
    entityType as validateEntityType
} from 'services/validators';

import {
    point as convertPoint, convertBoundingBox, array as convertArray,
    commaSeparated as convertCommaSeparated
} from 'services/converters';
import parameterApplications from 'common/parameterApplications';
import { v4 as uuid } from 'uuid';

export default {
    create: () => {
        return {
            extendedPostalCodesFor: {
                validators: [validateString]
            },
            type: {
                defaultValue: 'geocode',
                application: parameterApplications.PATH
            },
            key: {
                validators: [validateString]
            },
            trackingId: {
                validators: [validateTrackingId],
                application: parameterApplications.HEADER,
                defaultValue: uuid
            },
            query: {
                validators: [validateString],
                required: true,
                application: parameterApplications.PATH
            },
            typeahead: {
                validators: [validateBool]
            },
            limit: {
                validators: [validatePositiveInteger]
            },
            view: {
                validators: [validateOneOfValue(
                    ['AR', 'IL', 'MA', 'IN', 'PK', 'Unified', 'RU', 'TR', 'CN'],
                    'view')]
            },
            offset: {
                validators: [validateNaturalInteger],
                name: 'ofs'
            },
            language: {
                validators: [validateLanguageCode]
            },
            boundingBox: {
                validators: [validateBoundingBox], converters: [convertBoundingBox],
                cast: (boundingBox, requestOptions) => {
                    requestOptions.topLeft = `${boundingBox.maxLat},${boundingBox.minLon}`;
                    requestOptions.btmRight = `${boundingBox.minLat},${boundingBox.maxLon}`;
                }
            },
            center: {
                converters: [convertPoint],
                validators: [validatePoint],
                cast: (center, requestOptions) => {
                    const coordinates = center.split(',');
                    requestOptions.lat = coordinates[0];
                    requestOptions.lon = coordinates[1];
                }
            },
            countrySet: {
                validators: [validateCountrySet]
            },
            radius: {
                validators: [validateNaturalInteger]
            },
            bestResult: {
                validators: [validateBool],
                cast: (bestResults, requestOptions) => {
                    if (!bestResults) {
                        return;
                    }
                    requestOptions.limit = 1;
                    requestOptions.ofs = 0;
                }
            },
            protocol: {
                validators: [validateOneOfValue(['http', 'https'], 'protocol')]
            },
            timeZone: {
                validators: [validateOneOfValue(['iana'], 'timeZone parameter')]
            },
            entityTypeSet: {
                converters: [convertArray, convertCommaSeparated],
                validators: [validateEntityType]
            }
        };
    }
};
