/* eslint-disable max-len */
/**
 * Provides detailed information about traffic incidents in the requested area
 * using the TomTom [Traffic API - Traffic Incidents Details](TRAFFIC_INCIDENT_DETAILS_URL).
 * This is a new version of the {{#crossLink "Services.services.incidentDetails"}}incidentDetails{{/crossLink}}
 * method with simpler options and a more readable response object.
 *
 * The current road situation is updated every minute.
 *
 * ### Response
 *
 * The fields that appear in the response depend on the value of the `fields` request parameter.
 *
 * The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * @class incidentDetailsV5
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses TrackingIdMixin
 * @uses ProtocolMixin
 * @uses AbortSignalMixin
 * @constructor
 * @param {Object} options Options object.
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 *
 * @example
 * ```javascript
 * tt.services.incidentDetailsV5({
 *   key: '<Your API key>',
 *   boundingBox: '4.8854592519716675,52.36934334773164,4.897883244144765,52.37496348620152'
 * }).then(function(response) {
 *   console.log(response);
 * });
 * ```
 */

import {
    validateBoundingBox,
    string as validateString,
    validIncidentDetailsV5Fields,
    languageCode as validateLanguageCode,
    key as validateKey,
    trackingId as validateTrackingId
} from './validators';
import {
    removeWhitespaces,
    convertBoundingBox,
    incidentDetailsV5Language as convertIncidentDetailsV5Language
} from './converters';
import { singleRequestServiceFactory, serviceFactory } from '../core';
import { v4 as uuid } from 'uuid';
import parameterApplications from '../common/parameterApplications';
import { SERVICE_TYPES } from 'Core/serviceTypes';
import { Endpoints } from '../endpoints/endpointsManager';
import { modelResponse } from '../model/modelResponse';

const fields = {
    key: {
        validators: [validateKey]
    },
    trackingId: {
        validators: [validateTrackingId],
        application: parameterApplications.HEADER,
        defaultValue: uuid
    },

    /**
     * The bounding box is a limited area within the search results.
     * Maximum area of bounding box is 10000km2.</br>
     *
     * This option is able to convert a number of popular formats into the bounding box.
     * The supported formats are listed below:
     *  -  {{#crossLink "Maps.LngLatBounds"}}{{/crossLink}} class instance
     *  - `{minLon: 0, minLat: 0, maxLon: 1, maxLat: 1}` A plain object with the keys minLon, minLat, maxLon, maxLat.
     *  - `[0, 0, 1, 1]` An array of numbers describing the bounding box following the order: minLon, minLat,
     *     maxLon, maxLat.
     *  - `[[0, 0], [1, 1]]` A two-dimensional array with two indexes [southWest, northEast], each one with
     *     longitude and latitude values.
     *  - `"0,0,1,1"` A comma-separated string with numbers in the order: minLon, minLat, maxLon, maxLat.
     *  - `[{lon: 0, lat: 0},{lon: 1, lat: 1}]` A one-dimensional array with two objects in the order: southWest,
     *     northEast, and each object with a `lat` and `lon` key.
     *  - `[{lng: 0, lat: 0},{lng: 1, lat: 1}]` A one-dimensional array with two objects in the order: southWest,
     *     northEast and each object with a `lat` and `lon` key.
     *  - `[{{#crossLink "Maps.LngLat"}}{{/crossLink}}, {{#crossLink "Maps.LngLat"}}{{/crossLink}}]`
     *      A one-dimensional array with two {{#crossLink "Maps.LngLat"}}{{/crossLink}}
     *      instances in the order: southWest and northEast.
     * @attribute boundingBox
     * @param {Maps.LngLatBounds|Number[]|Object[]|String} options.boundingBox Bounding box area in one of the supported formats.
     */
    boundingBox: {
        required: true,
        validators: [validateBoundingBox],
        converters: [convertBoundingBox],
        cast: (boundingBox, requestOptions) => {
            requestOptions.bbox = `${boundingBox.minLon},${boundingBox.minLat},${boundingBox.maxLon},${boundingBox.maxLat}`;
        }
    },

    /**
     * The fields to be included in the response, nested as in the response schema.
     * In order to obtain all data, it is necessary to place the whole object in the query.
     *
     * ```javascript
     * `{
     *   incidents {
     *     type,
     *     geometry {
     *       type,
     *       coordinates
     *     },
     *     properties {
     *       id,
     *       iconCategory,
     *       magnitudeOfDelay,
     *       events {
     *         description,
     *         code,
     *         iconCategory
     *       },
     *       startTime,
     *       endTime,
     *       from,
     *       to,
     *       length,
     *       delay,
     *       roadNumbers,
     *       aci {
     *         probabilityOfOccurrence
     *         numberOfReports
     *         lastReportTime
     *       }
     *     }
     *   }
     * }`
     * ```
     * @attribute fields
     * @param {String} [options.fields='{ incidents { type, geometry { type, coordinates }, properties { iconCategory } } }']
     */
    fields: {
        validators: [validateString, validIncidentDetailsV5Fields],
        converters: [removeWhitespaces]
    },

    /**
     * The Traffic Model ID is the reference value for the state of traffic at a particular time.
     * It is updated every minute, and is valid for two minutes before it times out.
     * If not provided the current traffic model ID is used.
     *
     * @attribute t
     * @param {String} [options.t] A valid, not older than two minutes, traffic model ID.
     */
    t: {
        validators: [validateString]
    },

    /**
     * The ISO 639-1 code for the output language.
     *
     * * Affects the `description` field in the response.
     * * When an invalid language code is provided the response is returned in English.
     * * When an incident description does not have a translation, an English description is returned.
     * @attribute language
     * @param {String} [options.language='en-GB'] Language code that decides in which language the results
     * should be returned.
     */
    language: {
        validators: [validateLanguageCode],
        converters: [convertIncidentDetailsV5Language]
    }
};

export function incidentDetailsV5(options, additionalOptions) {
    const endpoints = new Endpoints(additionalOptions);
    const singleRequest = singleRequestServiceFactory(endpoints.resolve('incidentDetailsV5Endpoint'));
    function handleServiceCall(requestOptions, abortSignal) {
        return singleRequest(fields, requestOptions, abortSignal).then(modelResponse);
    }

    return serviceFactory(
        fields,
        SERVICE_TYPES.TRAFFIC_INCIDENTS,
        'incidentDetailsV5',
        handleServiceCall
    )(options, additionalOptions);
}
