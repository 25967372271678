/* eslint-disable max-len */
/**
 * Makes a search request using the TomTom
 * [Search API - Along Route Search](ALONG_ROUTE_SEARCH_URL).
 *
 * The Search Along Route endpoint allows you to perform a fuzzy search for
 * POIs along a specified route. This search is constrained by specifying the
 * Detour Time limiting measure.
 *
 * To send the route points, this service will use a POST request with the  {{#crossLink "Services.services.alongRouteSearch/route:parameter"}}{{/crossLink}} encoded as a JSON payload.
 * The minimum number of route points is 2.
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * Additionally, the response extends API response by providing `toGeoJson()` method, which converts along route search data into
 * FeatureCollection with <a target="_blank" rel=”noopener” href="https://tools.ietf.org/html/rfc7946#section-3.1.2">Point</a> geometry.
 *
 * Each point feature represents `poi` from the original response. Properties of `poi` are mapped into feature properties
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 *```js
 * tt.services.alongRouteSearch({
 *     key: <Your API key>,
 *     limit: 20,
 *     maxDetourTime: 120,
 *     query: 'gas station',
 *     route: [
 *         {
 *             'lat': 37.7524152343544,
 *             'lon':-122.43576049804686
 *         },
 *         {
 *             'lat': 37.70660472542312,
 *             'lon':-122.43301391601562
 *         },
 *         [-122.36434936523438, 37.712059855877314], // Another valid format
 *     ]
 *   }).then(function(response) {
 *     console.log('SUMMARY:');
 *     console.table(response.summary);
 *     console.log('RESULTS:');
 *     console.table(response.results);
 *   });
 * ```
 *
 * For a list of all available formats for routes, read the documentation for the
 * {{#crossLink "Services.services.alongRouteSearch/route:parameter"}}{{/crossLink}} option.
 *
 * @class alongRouteSearch
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses QueryMixin
 * @uses BatchMixin
 * @uses BrandSetMixin
 * @uses OpeningHoursMixin
 * @uses CategorySetMixin
 * @uses ConnectorSetMixin
 * @uses TimeZoneMixin
 * @uses MapcodesMixin
 * @uses FuelSetMixin
 * @uses TrackingIdMixin
 * @uses MaxPowerKwMixin
 * @uses MinPowerKwMixin
 * @uses ViewMixin
 * @uses AbortSignalMixin
 * @constructor
 *
 * @param {Object} [options]
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 */
/* eslint-enable max-len */

import {SERVICE_TYPES} from 'Core/serviceTypes';
import {
    bool as validateBool, string as validateString, key as validateKey, trackingId as validateTrackingId,
    oneOfValue as validateOneOfValue, number as validateNumber, integerInInterval as validateIntegerInInterval,
    route as validateRoute, connectorSet as validateConnectorSet
} from '../validators';
import {route as convertRoute} from '../converters';
import { SEARCH_ALONG_ROUTE } from '../../common/searchTypes';
import parameterApplications from '../../common/parameterApplications';
import { v4 as uuid } from 'uuid';
import {modelResponse} from '../../model/modelResponse';
import { Endpoints } from '../../endpoints/endpointsManager';
import {searchModel} from '../../model/search/search';
import {
    singleRequestServiceFactory, batchRequestServiceFactory, serviceFactory
} from '../../core';

const searchType = SEARCH_ALONG_ROUTE;

const fields = {
    key: {
        validators: [validateKey]
    },

    trackingId: {
        validators: [validateTrackingId],
        application: parameterApplications.HEADER,
        defaultValue: uuid
    },

    /**
     * The maximum allowed value is 3600.
     *
     * @attribute maxDetourTime
     * @param {Number} [options.maxDetourTime] New maximum detour time in seconds.
     */
    maxDetourTime: {
        validators: [validateIntegerInInterval(1, 3600)],
        required: true
    },

    /**
     * @attribute spreadingMode
     * @param {String} [options.spreadingMode] Enables the spreading of returned results evenly along the route.
     */
    spreadingMode: {
        validators: [validateOneOfValue(['auto'], 'spreading mode')]
    },

    /**
     * The following formats are supported:
     *
     * - **String[]** (longitude/latitude pairs)
     *   - `["4.8,52.3", "4.8,52.3"]`
     * - **Array[]** (longitude/latitude pairs)
     *   - `[ [4.8,52.3], [4.8,52.3] ]`
     * - **Object[]**
     *   - `{lon: 4.8, lat: 52.3}`
     *   - `{lng: 5.8, lat: 53.3}`
     *   - `{x: 53.3, y: 5.8}`
     *   - `{longitude: 5.8, latitude: 53.3}`
     *   - `{lng: Function, lat: Function}` The functions should return a numeric value.
     *
     * @example
     *```js
     * function callbackFn(response) {
     *   console.log(response);
     * }
     *
     * tomtom.alongRouteSearch({
     *   route:[
     *     {
     *         lat: 37.7524152343544,
     *         lon:-122.43576049804686
     *     },
     *     {
     *         lat: 37.70660472542312,
     *         lon:-122.43301391601562
     *     }
     *   ]
     * })
     *
     * .then(callbackFn);
     *```
     *
     * @attribute route
     * @param {Array} [options.route] Route representation.
     */
    route: {
        required: true,
        converters: [convertRoute],
        validators: [validateRoute],
        application: parameterApplications.POST
    },

    query: {
        required: true,
        validators: [validateString],
        application: parameterApplications.PATH
    },

    // A custom "limit" docblock is used because the max limit value differs from the one used on the
    // other services (100).
    /**
     * @attribute limit
     * @param {Number} [options.limit=10] The maximum number of elements in the response. The maximum number is 20.
     */
    limit: {
        validators: [validateIntegerInInterval(1, 20)]
    },

    type: {
        validators: [validateString],
        defaultValue: searchType,
        application: parameterApplications.PATH
    },

    brandSet: {
        validators: [validateString]
    },

    categorySet: {
        validators: [validateString]
    },

    connectorSet: {
        validators: [validateConnectorSet]
    },

    minPowerKW: {
        validators: [validateNumber]
    },

    maxPowerKW: {
        validators: [validateNumber]
    },

    openingHours: {
        validators: [validateOneOfValue(['nextSevenDays'], 'openingHours parameter')]
    },

    timeZone: {
        validators: [validateOneOfValue(['iana'], 'timeZone parameter')]
    },

    /**
     * @attribute sortBy
     * @param {String} [options.sortBy='detourTime']  Parameter which provides possibility to sort returned results.
     * Values: `detourTime`, `detourDistance`, `detourOffset`
     *
     * * Detour distance will be calculated as a difference between the original distance and the distance of a new
     * route with Point Of Interest location.
     * * `detourDistance` field will be present in every response regardless of sorting parameter.
     * * If detour offset is set to false `detourOffset=false` and sorting parameter is set to detourOffset
     * `sortBy=detourOffset` then detour offset will be calculated and results will be sorted as expected, but
     * detourOffset value will not be present in the response.
     */
    sortBy: {
        validators: [validateOneOfValue(['detourTime', 'detourOffset', 'detourDistance'], 'sortBy')]
    },

    /**
     * @attribute detourOffset
     * @param {Boolean} [options.detourOffset=false] Parameter which turns on calculation of the distance between the
     * start of the route and the starting point of the detour to a POI.
     *
     * * Detour offset is also calculated when `sortBy` parameter is set to `detourOffset`.
     * * Value is provided in a response only when `detourOffset` is set to `true`.
     */
    detourOffset: {
        validators: [validateBool]
    },

    view: {
        validators: [validateString]
    }
};

export function alongRouteSearch(options, additionalOptions) {
    const endpoints = new Endpoints(additionalOptions);
    const singleRequest = singleRequestServiceFactory(endpoints.resolve('searchEndpoint'));
    const batchRequest = batchRequestServiceFactory(100, {
        single: endpoints.resolve('batchSearchQueryEndpoint'),
        batchSync: endpoints.resolve('batchSyncSearchEndpoint'),
        batch: endpoints.resolve('batchSearchEndpoint')
    });

    const handleServiceCall = (data, abortSignal) => {
        return singleRequest(fields, data, abortSignal)
            .then(response => modelResponse(response, searchModel));
    };
    const handleBatchServiceCall = (data, abortSignal) => {
        return batchRequest(fields, data, abortSignal)
            .then(response => modelResponse(response, searchModel));
    };

    return serviceFactory(
        fields,
        SERVICE_TYPES.SEARCH,
        'alongRouteSearch',
        handleServiceCall,
        handleBatchServiceCall
    )(options, additionalOptions);
}
