/* eslint-disable max-len */
/**
 * Makes a search request using the TomTom
 * [Search API - Geometry Search](GEOMETRY_SEARCH_URL).
 *
 * Parameters need to be passed to the constructor.
 *
 * ### Response
 * The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * Additionally, the response extends API response by providing `toGeoJson()` method, which converts geometry search data into
 * FeatureCollection with <a target="_blank" rel=”noopener” href="https://tools.ietf.org/html/rfc7946#section-3.1.2">Point</a> geometry.
 *
 * Each point feature represents `poi` from the original response. Properties of `poi` are mapped into feature properties
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @example
 * ```js
 * const geometryList = [
 *   {
 *     type: 'POLYGON',
 *     vertices: [
 *       '-122.43576049804686,37.7524152343544',
 *       '-122.43301391601562,37.70660472542312',
 *       '-122.36434936523438,37.712059855877314',
 *       '-122.37396240234374,37.75350561243041'
 *     ]
 *   },
 *   {
 *     type: 'CIRCLE',
 *     position: '-121.36434,37.71205',
 *     radius: 6000
 *   },
 *   {
 *     type: 'CIRCLE',
 *     position: '-121.36434,37.31205',
 *     radius: 1000
 *   }
 * ];
 *
 * function callbackFn(results) {
 *   console.log(results);
 * }
 *
 * tt.services.geometrySearch({
 *   key: <Your API key>,
 *   geometryList: geometryList
 * }).then(callbackFn);
 * ```
 *
 * @class geometrySearch
 * @extends Search
 * @namespace Services.services
 * @uses QueryMixin
 * @uses IdxSetMixin
 * @uses ExtendedPostalCodesForMixin
 * @uses GeometryList
 * @uses ViewMixin
 * @uses OpeningHoursMixin
 * @uses MapcodesMixin
 * @uses ConnectorSetMixin
 * @uses BrandSetMixin
 * @uses CategorySetMixin
 * @uses MinPowerKwMixin
 * @uses MaxPowerKwMixin
 * @uses AbortSignalMixin
 * @constructor
 * @module Services
 *
 * @param {Object} [options] Options to be passed to the search call,
 *     or an array of such options objects to perform a batch request.
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 * @param {String|String[]} [options.entityTypeSet]
 * An array or comma-separated list of entity types which can be used to restrict the result
 * to a specific entity type. __Parameter should be used with the idxSet parameter
 * set to the Geography value.__
 * <br><br>
 * Available values:
 *  - `Country`
 *  - `CountrySubdivision`
 *  - `CountrySecondarySubdivision`
 *  - `CountryTertiarySubdivision`
 *  - `Municipality`
 *  - `MunicipalitySubdivision`
 *  - `Neighbourhood`
 *  - `PostalCodeArea`
 */
/* eslint-enable */

import {
    string as validateString, key as validateKey, oneOfValue as validateOneOfValue,
    languageCode as validateLanguageCode, entityType as validateEntityType, limit as validateLimit,
    geometryList as validateGeometryList
} from '../validators';
import {
    geometryList as convertGeometryList, array as convertArray, commaSeparated as convertCommaSeparated
} from '../converters';
import { Search } from './search';
import { boundingBox } from './searchParameters';
import parameterApplications from '../../common/parameterApplications';
import { GEOMETRY_SEARCH } from '../../common/searchTypes';

class GeometrySearch extends Search {
    constructor(options, additionalOptions) {
        super(options, additionalOptions);

        this.fields.type = {
            defaultValue: GEOMETRY_SEARCH,
            visible: false,
            application: parameterApplications.PATH
        };

        this.fields.key = {
            validators: [validateKey]
        };

        this.fields.limit = {
            validators: [validateLimit]
        };

        this.fields.language = {
            validators: [validateLanguageCode]
        };

        this.fields.geometryList = {
            validators: [validateGeometryList],
            converters: [convertGeometryList],
            application: parameterApplications.POST
        };

        this.fields.idxSet = {
            validators: [validateString]
        };

        this.fields.protocol = {
            validators: [validateOneOfValue(['http', 'https'], 'protocol')]
        };

        this.fields.extendedPostalCodesFor = {
            validators: [validateString]
        };

        this.fields.entityTypeSet = {
            converters: [convertArray, convertCommaSeparated],
            validators: [validateEntityType]
        };

        this.fields.boundingBox = boundingBox();
    }
}

export function geometrySearch(options, additionalOptions) {
    return (new GeometrySearch(options, additionalOptions)).construct('geometrySearch');
}
