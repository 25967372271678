/**
 * The Flow Segment Data service provides information about the speeds and travel times of the road
 * fragment closest to the given coordinates.
 *
 * ### Response
 * The response is extended with `getTrackingId()` method, which returns the `Tracking-ID`
 * associated with the request.
 *
 * Please refer to {{#crossLinkModule "Services"}}Difference between
 * API responses and this library's responses{{/crossLinkModule}} section.
 *
 * @class trafficFlowSegmentData
 * @namespace Services.services
 * @module Services
 * @uses KeyMixin
 * @uses TrackingIdMixin
 * @uses AbortSignalMixin
 * @param {Object} options Options to be passed to the flowSegmentData call.
 * @param {Object} [additionalOptions] Additional options to be passed to the service.
 * @constructor
 */

import {SERVICE_TYPES} from 'Core/serviceTypes';
import {
    numberInInterval as validateNumberInInterval, zoomLevel as validateZoomLevel, trackingId as validateTrackingId,
    oneOfValue as validateOneOfValue, string as validateString, point as validatePoint, bool as validateBool
} from './validators';
import {point as convertPoint} from './converters';
import {flowSegmentDataModel} from '../model/traffic/flowSegmentData';
import parameterApplications from '../common/parameterApplications';
import { modelResponse } from '../model/modelResponse';
import { v4 as uuid } from 'uuid';
import { Endpoints } from '../endpoints/endpointsManager';
import { singleRequestServiceFactory, serviceFactory } from '../core';

const fields = {
    key: {
        validators: [validateString]
    },
    trackingId: {
        validators: [validateTrackingId],
        application: parameterApplications.HEADER,
        defaultValue: uuid
    },

    /**
     * @attribute zoom
     * @param {Number} options.zoom Zoom level. This will affect traffic incident coordinates
     * to separately present both road sides. Should be in the range from 0 to 18.
     */
    zoom: {
        validators: [validateZoomLevel],
        required: true,
        application: parameterApplications.PATH
    },

    /**
     * @attribute style
     * @param {String} options.style The type of flow data to be displayed on the tile. Available options: absolute,
     * relative, and relative-delay.
     */
    style: {
        validators: [validateOneOfValue([
            'absolute',
            'relative',
            'relative-delay',
            'relative0',
            'relative0-dark',
            'reduced-sensitivity'
        ], 'traffic flow style')],
        required: true,
        application: parameterApplications.PATH
    },

    /**
     * @attribute point
     * @param {String} options.point Coordinates of the point close to the road segment.
     * They have to be comma-separated and calculated using the EPSG4326 projection.
     */
    point: {
        converters: [convertPoint],
        validators: [validatePoint],
        required: true
    },

    /**
     * @attribute unit
     * @param {String} [options.unit] Unit of speed. Avilable values: KMPH and MPH.
     */
    unit: {
        validators: [validateOneOfValue(['KMPH', 'MPH'])]
    },

    /**
     * @attribute thickness
     * @param {Number} [options.thickness] The segment width multiplier. Value should be in the range from 1 to 20.
     */
    thickness: {
        validators: [validateNumberInInterval(1, 20)]
    },

    /**
     * @attribute openLr
     * @param {Boolean} [options.openLr] Specifies if the Response includes OpenLR code.
     */
    openLr: {
        validators: [validateBool]
    }
};

export function trafficFlowSegmentData(options, additionalOptions) {
    const endpoints = new Endpoints(additionalOptions);
    const singleRequest = singleRequestServiceFactory(endpoints.resolve('flowSegmentDataEndpoint'));

    const handleServiceCall = (data, abortSignal) => {
        return singleRequest(fields, data, abortSignal).then(response => modelResponse(response, flowSegmentDataModel));
    };
    return serviceFactory(
        fields,
        SERVICE_TYPES.TRAFFIC_FLOW,
        'trafficFlowSegmentData',
        handleServiceCall
    )(options, additionalOptions);
}
